import Editor from '@monaco-editor/react';
import { FileManagerFile, useFileContentsSuspense } from 'api/site';
import { forwardRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import he from 'he';
import { useTheme } from '@emotion/react';
import { cssVar } from 'utils/css';

interface FileEditorProps {
  readonly file: FileManagerFile;
}

const FileEditor = forwardRef(function FileEditor({ file }: FileEditorProps, ref) {
  const theme = useTheme();
  const { siteId } = useParams<{ siteId: string }>();

  const fileContents = useFileContentsSuspense(siteId!, file.name, file.path);

  const decodedContent = useMemo(() => {
    return he.decode(fileContents.data.data.result.content, {
      strict: true,
    });
  }, []);

  return (
    <Editor
      value={decodedContent}
      path={file.name}
      options={{
        scrollBeyondLastLine: false,
        minimap: { enabled: false },
      }}
      beforeMount={monaco => {
        monaco.editor.defineTheme('rocket-light', {
          base: 'vs',
          inherit: true,
          rules: [
            // CSS variable colors do not change in dark mode
            { token: 'global', foreground: cssVar('--color-blue-800'), fontStyle: 'bold' },
            { token: 'keyword', foreground: cssVar('--color-blue-600'), fontStyle: 'bold' },
            { token: 'variable', foreground: cssVar('--color-orange-600') },
            { token: 'comment', foreground: cssVar('--color-grey-500') },
            { token: 'number', foreground: cssVar('--color-orange-600') },
            { token: 'string', foreground: cssVar('--color-green-600') },
          ],
          colors: {
            'editor.foreground': cssVar('--color-grey-900'),
            'editor.background': cssVar('--color-grey-50'),
            'editor.selectionBackground': cssVar('--color-blue-200'),
            'editor.lineHighlightBackground': cssVar('--color-grey-100'),
            'editorCursor.foreground': cssVar('--color-blue-700'),
            'editorWhitespace.foreground': cssVar('--color-grey-400'),
            'editorLineNumber.foreground': cssVar('--color-grey-500'),
          },
        });
        monaco.editor.defineTheme('rocket-dark', {
          base: 'vs-dark',
          inherit: true,
          rules: [
            { token: 'global', foreground: cssVar('--color-blue-200'), fontStyle: 'bold' },
            { token: 'keyword', foreground: cssVar('--color-blue-400'), fontStyle: 'bold' },
            { token: 'variable', foreground: cssVar('--color-orange-400') },
            { token: 'comment', foreground: cssVar('--color-grey-500') },
            { token: 'number', foreground: cssVar('--color-orange-400') },
            { token: 'string', foreground: cssVar('--color-green-400') },
          ],
          colors: {
            'editor.foreground': cssVar('--color-grey-50'),
            'editor.background': cssVar('--color-grey-900'),
            'editor.selectionBackground': cssVar('--color-blue-800'),
            'editor.lineHighlightBackground': cssVar('--color-grey-800'),
            'editorCursor.foreground': cssVar('--color-blue-200'),
            'editorWhitespace.foreground': cssVar('--color-grey-600'),
            'editorLineNumber.foreground': cssVar('--color-grey-500'),
          },
        });
      }}
      theme={(theme as any).palette.mode === 'dark' ? 'rocket-dark' : 'rocket-light'}
      onMount={editor => {
        if (ref && typeof ref === 'object') {
          ref.current = editor;
        }
      }}
    />
  );
});

export default FileEditor;
